import React, { useState, useCallback, useEffect } from "react"
import {
  Card,
  FormLayout,
  TextField,
  Button,
  Icon,
  Layout,
  Stack,
  Popover,
  ActionList,
  Modal,
  TextStyle,
  ButtonGroup,
  Form,
  Checkbox,
  ChoiceList,
  Link,
  Select,
} from "@shopify/polaris"
import {
  ImageMajor,
  TextBlockMajor,
  ColumnWithTextMajor,
  HeaderMajor,
  DeleteMajor,
  ChevronUpMinor,
  ChevronDownMinor,
} from "@shopify/polaris-icons"
import update from "immutability-helper"
import Divider from '../../../components/divider'
import SmartRules from '../offers/smartRules'
import CreateOfferSteps from '../offers/createOfferSteps'
import DocSave from "../../../components/docSave"
const deliveryTypes = [
    {
      label: "Local delivery",
      value: "LOCAL",
    },
    {
      label: "Local pickup",
      value: "PICK_UP",
    },
    {
      label: "Shipping to a pickup point",
      value: "PICKUP_POINT",
    },
    {
      label: "Retail",
      value: "RETAIL",
    },
    {
      label: "Shipping",
      value: "SHIPPING",
    },
  ]
const HideDeliveryMethodsForm = ( props ) => {
const { token, host, shop,location, state, setState, productPickerShow, setProductPickerShow, currencySymbol, isCreating, isEditing, isSubmitting, hasUnsavedChanges, handleFormSubmit, handleCustomContentDelete } = props
const [isLoading, setIsLoading] = useState(false)
  const [popoverActive, setPopoverActive] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const addSmartRule =  () => {
    const conditions = {
      conditions:{
          all:[
              {
                  any: [
                      {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                  ]
              }
              ]
      },
      event: { 
          type: 'foundout',
          params: {
            message: 'rule has found out!'
          }
      }
  }
    setState(update(state, { triggers: { $set: conditions }}))
  }
  const togglePopoverActive = () => {
    setPopoverActive((popoverActive) => !popoverActive)
    setExpanded(!expanded)
  }
  const handleInputChange = (value, name) => {
    setState(update(state, { deliveryMethodName: { $set: value } }))
  }
  const deliveryMethodsMarkup = (type) => {
    switch (true) {
      case "nameContains" === type || "nameNotContains" === type:
        return (
          <TextField
            type="text"
            label="Delivery method"
            value={state?.deliveryMethodName}
            onChange={(value) => handleInputChange(value, "deliveryMethodName")}
            disabled={isLoading}
            requiredIndicator
            labelHidden
          />
        )
      case "costIs" === type ||
        "costMoreThan" === type ||
        "costLessThan" === type:
        return (
          <TextField
            value={state?.deliveryMethodName}
            onChange={(value) => handleInputChange(value, "deliveryMethodName")}
            autoComplete="off"
            placeholder="0.00"
            prefix={"$"}
            type="number"
          />
        )
      case "allDeliveryMethods" === type:
        return false
      case "deliveryType" === type:
        return (
          <Popover
            active={popoverActive}
            activator={
              <Button
                onClick={togglePopoverActive}
                textAlign="left"
                disclosure={expanded ? "up" : "down"}
                fullWidth
              >
                {!Array.isArray(state?.deliveryMethodName) ||
                (Array.isArray(state?.deliveryMethodName) &&
                state?.deliveryMethodName.length === 0)
                  ? "Select delivery types"
                  : deliveryTypes
                      .filter((d) =>
                        state?.deliveryMethodName.includes(d.value)
                      )
                      .map((d) => d.label)
                      .join(", ")}
              </Button>
            }
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
            fullWidth
            sectioned
          >
            <ChoiceList
              allowMultiple
              choices={deliveryTypes}
              selected={
                Array.isArray(state?.deliveryMethodName)
                  ? state?.deliveryMethodName
                  : []
              }
              onChange={(value) =>
                handleInputChange(value, "deliveryMethodName")
              }
            />
          </Popover>
        )
      default:
        return (
          <TextField
            type="text"
            label="Delivery method"
            value={state?.deliveryMethodName}
            onChange={(value) => handleInputChange(value, "deliveryMethodName")}
            disabled={isLoading}
            requiredIndicator
            labelHidden
          />
        )
    }
  }
  console.log("state", state)
  return (
      <Layout>
        <Layout.Section>
          {isCreating && (
          <div style={{margin: '20px 0 10px -25px'}}>
            <CreateOfferSteps step={2}/>
          </div>
          )}
          <div style={{ paddingRight: '3rem', marginBottom: 50, }}>
            <Form 
                  disabled={
                    !state.name 
                  }
                  onSubmit={handleFormSubmit}>
            <FormLayout>
              <Card sectioned>
                <FormLayout>
                <FormLayout.Group>
                    <Stack alignment="center">
                      <Stack.Item>
                        <Select
                          label="Delivery method option"
                          labelHidden
                          options={[
                            { label: "Name contains", value: "nameContains" },
                            {
                              label: "Name does not contain",
                              value: "nameNotContains",
                            },
                            {
                              label: "All delivery methods",
                              value: "allDeliveryMethods",
                            },
                            { label: "Delivery cost is", value: "costIs" },
                            {
                              label: "Delivery cost is more than",
                              value: "costMoreThan",
                            },
                            {
                              label: "Delivery cost is less than",
                              value: "costLessThan",
                            },
                            { label: "Delivery type", value: "deliveryType" },
                          ]}
                          onChange={(value) => {
                            if ("deliveryType" === value) {
                                setState(update(state, { deliveryMethodNameOption: { $set: value }, deliveryMethodName: { $set: [] } }))
                              } else {
                                setState(update(state, { deliveryMethodNameOption: { $set: value }, deliveryMethodName: { $set: "" } }))
                              }
                          }}
                          value={state?.deliveryMethodNameOption}
                        />
                      </Stack.Item>
                      <Stack.Item fill>
                        <Stack alignment="center">
                          <Stack.Item fill>
                            {deliveryMethodsMarkup(
                              state?.deliveryMethodNameOption
                            )}
                          </Stack.Item>
                          {/*
                          <Stack.Item>
                            <DeleteWrapper
                              onClick={() =>
                                console.log("Delete delivery method")
                              }
                            >
                              <Icon source={DeleteMajor} color="critical" />
                            </DeleteWrapper>
                          </Stack.Item>
                            */}
                        </Stack>
                      </Stack.Item>
                    </Stack>
                  </FormLayout.Group>
                </FormLayout>
              </Card>
              <Card sectioned>
                <FormLayout>
                  <h6>
                  <TextStyle variation="strong">
                    Display conditions
                  </TextStyle>
                  </h6>
                  <SmartRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} type={"customization"} factsOptionsValue={[
                  { label: "Cart products", value: "cartProducts" },
                  { label: "Cart total", value: "cartTotal" },
                  { label: "Customer", value: "customer" },
                ]} customerOperatorOptionsValue={[{label: 'Has Language', value: 'hasCustomerLanguage'}]}/>
                </FormLayout>
              </Card>
              
            </FormLayout>
            <DocSave isCreating={isCreating} isEditing={isEditing} isSubmitting={isSubmitting} hasUnsavedChanges={hasUnsavedChanges} handleFormSubmit={handleFormSubmit} handleCustomContentDelete={handleCustomContentDelete} location={location}/>
              </Form>
            </div>
        </Layout.Section>
       
      </Layout>
  )
}

export default HideDeliveryMethodsForm